import React from 'react';
import {
  Drawer, Button, Input, Radio, Select, message, Upload, Form as AntdForm,
} from 'antd';
import {
  ISurvey, ISurveyQuestion, ISurveyResponseObject,
} from '@bridgelabsdesign/gfox-api-client';
import { AiFillCloseCircle } from 'react-icons/ai';
import { UploadOutlined } from '@ant-design/icons';
import { SurveyQuestionTypeId, QuestionType } from '@bridgelabsdesign/gfox-api-client/dist/utils/enums';
import { observer } from 'mobx-react-lite';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import completedSurvey from '../../assets/illustrations/completed-survey.svg';

import './style.less';
import stores from '../../stores/stores';
import Spin from '../Spin/Spin';
import { LegalDocuments } from '../../utils/legal';

type DrawerProps = {
    visible: boolean
    setVisible: Function,
    reviewsList: any[]
    selectedReview: any,
}

const ReviewDrawer = observer(({
  visible, setVisible, reviewsList, selectedReview,
}: DrawerProps) => {
  const [clickedItemInx, setClickedItemInx] = React.useState(0);
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [survey, setSurvey] = React.useState<ISurvey | undefined>();
  const [clickedReview, setClickedReview] = React.useState<ISurvey | null>(null);
  const [responseData, setResponseData] = React.useState<Record<string, ISurveyResponseObject>>({});
  const [isPosting, setIsPosting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('');
  const [selectedClosingMsg, setSelectedClosingMsg] = React.useState<string | null>(null);

  const [form] = AntdForm.useForm();

  const { TextArea } = Input;

  const onClose = () => {
    setVisible(false);
  };
  const { surveyStore, surveyResponseStore } = stores;

  const getSurvey = async (review:ISurvey) => {
    setLoading(true);
    setLoadingText('Getting Questions');
    const result = await surveyStore.loadSurveyById(review.id);
    console.log(result);
    setSurvey(result);
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const addQuestionResponses = async (responseValuesArray: ISurveyResponseObject[], survey:ISurvey) => {
    const formData = new FormData();
    formData.append('surveyId', survey.id);
    formData.append('response', JSON.stringify(responseValuesArray));
    fileList.forEach((file) => {
      if (file.originFileObj) {
        formData.append('file', file.originFileObj, file.name);
      }
    });
    setIsPosting(true);
    try {
      await surveyResponseStore.postSurveyResponse(formData, []);
      setVisible(false);
      setIsPosting(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saIdPattern = new RegExp('^[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\\d{4}(0|1)8\\d$');
  const phonePattern = new RegExp('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$');

  const respondToQuestion = async () => {
    try {
      await form.validateFields();
      console.log('Full responseData:', responseData);
      const values = Object.values(responseData);
      console.log('Values:', values);
      console.log('Survey questions length:', survey?.surveyQuestions.length);
      const errors: string[] = [];

      survey?.surveyQuestions.forEach((q) => {
        const answer = responseData[q.id]?.value?.[0] || '';
        if (q.text && q.questionTypeId === SurveyQuestionTypeId.Text && q.text.includes('ID number')) {
          if (!saIdPattern.test(answer)) {
            errors.push('Invalid South African ID number format.');
          }
        } else if (q.text && q.questionTypeId === SurveyQuestionTypeId.Text && q.text.includes('contact number')) {
          if (!phonePattern.test(answer)) {
            errors.push('Invalid phone number format.');
          }
        }
        if (q.text && q.text.toLowerCase().includes('email')) {
          console.log('Email: ', q.text);
          // eslint-disable-next-line no-param-reassign
          responseData[q.id].type = QuestionType.Email;
        }
        if (q.text && /\bname\b/i.test(q.text)) {
          console.log('Name: ', q.text);
          // eslint-disable-next-line no-param-reassign
          responseData[q.id].type = QuestionType.Name;
        }
      });

      if (errors.length > 0) {
        errors.forEach((err) => message.error(err));
        return;
      }

      if (values.length !== survey?.surveyQuestions.length) {
        message.error('Please answer every question to submit application.');
      } else if (clickedReview !== null) {
        const formData = new FormData();
        formData.append('surveyId', clickedReview.id);
        formData.append('response', JSON.stringify(values));
        fileList.forEach((file) => {
          formData.append('file', file.originFileObj as any);
        });

        const hide = message.loading('Adding your response', 0);
        setIsPosting(true);
        try {
        // eslint-disable-next-line no-param-reassign
          reviewsList[clickedItemInx].completed = true;
          const oldClickedItemInx = clickedItemInx;
          if (oldClickedItemInx < reviewsList.length - 1) {
            setClickedItemInx(oldClickedItemInx + 1);
            setClickedReview(reviewsList[oldClickedItemInx + 1]);
            reviewsList.splice(oldClickedItemInx);
          } else if (reviewsList.length > 1) {
            setClickedItemInx(0);
            setClickedReview(reviewsList[0]);
            reviewsList.splice(oldClickedItemInx);
          }
          setIsPosting(false);
          hide();
        } catch (error) {
          hide();
          setIsPosting(false);
          console.log(error);
        }
        addQuestionResponses(values, survey as unknown as ISurvey);
      }
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  React.useEffect(() => {
    if (selectedReview) {
      console.log(selectedReview);
      setClickedItemInx(reviewsList.map((x) => x.id).indexOf(selectedReview.id));
      setClickedReview(selectedReview);
    }
  }, [selectedReview]);

  React.useEffect(() => {
    console.log('Drawer test');
    if (clickedReview) {
      getSurvey(clickedReview);
    }
  }, [clickedReview]);

  React.useEffect(() => {
    setSelectedClosingMsg(survey?.closingMessage || null);
  }, [survey]);

  const onGeneralChange = (value: any, q: ISurveyQuestion) => {
    setResponseData((prev) => ({
      ...prev,
      [q.id]: {
        questionId: q.id,
        type: QuestionType.Text,
        value: Array.isArray(value) ? value : [value],
      },
    }));
  };

  const onRadioChange = (e: any, q: ISurveyQuestion) => {
    onGeneralChange(e.target.value, q);
  };

  const onSelectChange = (value: any, q: ISurveyQuestion) => {
    onGeneralChange(value, q);
  };

  const onUploadChange = (e: UploadChangeParam<UploadFile<any>>, q: ISurveyQuestion) => {
    const newFiles: UploadFile<any>[] = e.fileList.filter(Boolean);

    setFileList((prevFiles) => {
      const uniqueFiles = [...prevFiles, ...newFiles].filter(
        (file, index, self) => self.findIndex((f) => f.uid === file.uid) === index,
      );
      return uniqueFiles;
    });

    const fileNames = newFiles.map((file) => file.name);
    onGeneralChange(fileNames, q);
  };

  const renderElement = (q: ISurveyQuestion) => {
    switch (q.questionTypeId) {
      case SurveyQuestionTypeId.Email:
        return (
          <AntdForm.Item
            name={q.id}
            rules={[
              { required: true, message: 'Email is required!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input
              type="email"
              onChange={(e) => onGeneralChange(e.target.value, q)}
            />
          </AntdForm.Item>
        );
      case SurveyQuestionTypeId.Text:
        return <TextArea key={q.id} onChange={(e) => onGeneralChange(e.target.value, q)} rows={3} />;
      case SurveyQuestionTypeId.Number:
        return (
          <Select
            key={q.id}
            size="middle"
            onChange={(value) => onSelectChange(value, q)}
            style={{ width: '100%' }}
          >
            {q.options?.map((option: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Select.Option key={index} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        );
      case SurveyQuestionTypeId.MultipleChoice:
        if (q.text && q.text.toLowerCase().includes('popi')) {
          return (
            <AntdForm.Item name={q.id}>
              <Radio.Group onChange={(e) => onRadioChange(e, q)}>
                {q.options?.map((option: string, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Radio key={index} value={option}>
                    {option}
                  </Radio>
                ))}
              </Radio.Group>
              <div style={{ marginTop: '8px', fontSize: '1rem' }}>
                By selecting
                {' '}
                <strong>Yes</strong>
                {' '}
                you agree to the terms outlined in our
                {' '}
                <a
                  href={LegalDocuments.PRIVACY_POLICY}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'red', textDecoration: 'underline' }}
                >
                  Privacy Policy
                </a>
                .
              </div>
            </AntdForm.Item>
          );
        }
        return (
          <Radio.Group onChange={(e) => onRadioChange(e, q)} className="multi-choice">
            {q.options?.map((m: any, inx: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <Radio key={inx} value={m}>{m}</Radio>
            ))}
          </Radio.Group>
        );
      case SurveyQuestionTypeId.MultiSelect:
        return (
          <Select
            key={q.id}
            mode="tags"
            size="large"
            onChange={(value) => onSelectChange(value, q)}
            style={{ width: '100%', height: '60px' }}
            placeholder="Select an item or type something new and press enter"
          >
            {q.options?.map((option: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Select.Option key={index} value={option}>{option}</Select.Option>
            ))}
          </Select>
        );
      case SurveyQuestionTypeId.Document:
        return (
          <Upload
            key={q.id}
            name="file"
            action="/upload.do"
            onChange={(info) => onUploadChange(info, q)}
            onRemove={(file) => {
              setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));
            }}
            multiple
            style={{ width: '100%', height: '60px' }}
            accept=".pdf,.doc,.docx,.png"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        );
      default:
        return <span>Unsupported question type</span>;
    }
  };

  return (
    <Drawer
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      width="100%"
      className="review-drawer"
      closeIcon={<AiFillCloseCircle style={{ fontSize: '4.2rem', color: '#D8232A' }} />}
    >
      <Spin tip={loadingText} spinning={loading}>
        <AntdForm form={form} layout="vertical">
          <div className="review-drawer-root">
            {survey && (
              <>
                {survey.surveyQuestions.length > 0 ? (
                  <div className="survey-droppable-area survey-response-area">
                    {reviewsList.length >= 0 ? (
                      <>
                        {survey.surveyQuestions.map((q, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                          <div key={i} className="question">
                            <div className="question-top">
                              <span className="question-title">{(q.text ?? '')}</span>
                              <span className="question-controls" />
                            </div>
                            <div className="question-element">
                              {renderElement(q)}
                            </div>
                          </div>
                        ))}
                        <div key={-201} className="welcome-closing-messsage">
                          <span className="opening-msg">
                            {selectedClosingMsg}
                          </span>
                        </div>
                        <Button disabled={isPosting} onClick={() => respondToQuestion()} className="next-survey-btn">Submit</Button>
                      </>
                    ) : (
                      <div className="survey-completed-view">
                        <span className="thanks-text">You have completed all your surveys!</span>
                        <img className="completed-img" alt="" src={completedSurvey} />
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </AntdForm>
      </Spin>
    </Drawer>
  );
});

export default ReviewDrawer;
