import { IClient, IMasterStock } from '@bridgelabsdesign/gfox-api-client';
import { getStockDifference } from './stock';

type CartStoreType = {
  lineItemCount: number;
};

export type StockMessageContent = Pick<
  IMasterStock,
  'qtyAvailable' | 'isAppPrice'
>;

// eslint-disable-next-line import/prefer-default-export, max-len
export const getStockMessage = (
  cartStore: CartStoreType,
  masterStock: StockMessageContent | null | undefined,
  currentClient: IClient | null | undefined,
): {
  message: string;
  isOutOfStock: boolean;
  isAppPrice: boolean;
  isCOD: boolean;
} => {
  const isAccHolder = !!currentClient?.accountNumber;
  const stockDifference = getStockDifference(
    cartStore.lineItemCount,
    masterStock,
  );

  // App only prices
  if (
    masterStock?.isAppPrice
    && (stockDifference > 0 || masterStock?.qtyAvailable === 0)
  ) {
    // return `Insufficient stock only ${cartStore.currentInvItem?.masterStock?.qtyAvailable} available.`;
    return {
      message:
        masterStock?.qtyAvailable === 0
          ? 'Selected Size – Out Of Stock – available on Back Order'
          : `Insufficient stock only ${masterStock?.qtyAvailable} available.`,
      isOutOfStock: true,
      isAppPrice: true,
      isCOD: !isAccHolder,
    };
  }

  if (stockDifference <= 0) {
    return {
      message: '',
      isOutOfStock: false,
      isAppPrice: false,
      isCOD: !isAccHolder,
    };
  } // In stock

  if (stockDifference === cartStore.lineItemCount) {
    // Completely out of stock
    if (isAccHolder) {
      // return 'Out of Stock - available on Back Order.';
      return {
        message: 'Selected Size – Out Of Stock – available on Back Order',
        isOutOfStock: true,
        isAppPrice: false,
        isCOD: !isAccHolder,
      };
    }
    // return 'Out of Stock';
    return {
      message: 'Selected Size – Out Of Stock – available on Back Order',
      isOutOfStock: true,
      isAppPrice: false,
      isCOD: !isAccHolder,
    };
  }

  // Partial stock
  if (isAccHolder) {
    // return `Insufficient stock only ${cartStore.currentInvItem?.masterStock?.qtyAvailable} available and ${stockDifference} back order.`;
    // eslint-disable-next-line max-len
    return {
      message: `Insufficient stock only ${masterStock?.qtyAvailable} available and ${stockDifference} back order.`,
      isOutOfStock: true,
      isAppPrice: false,
      isCOD: !isAccHolder,
    };
  }

  // return `Insufficient stock only ${cartStore.currentInvItem?.masterStock?.qtyAvailable} available.`;
  return {
    message: `Insufficient stock only ${masterStock?.qtyAvailable} available.`,
    isOutOfStock: true,
    isAppPrice: false,
    isCOD: !isAccHolder,
  };
};
