/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import {
  Switch, Route, Redirect, RouteProps, useHistory, Router,
} from 'react-router-dom';
import { message } from 'antd';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Account from './pages/Account/Account';
import Lost from './pages/Lost/Lost';
import Notification from './pages/Notification/Notification';
import Permission from './pages/Permission/Permission';
import Layouts from './layouts/index';
import Menu from './components/Menu/Menu';
import Products from './pages/Products/Products';
import ProductInformation from './pages/ProductInformation/ProductInformation';
import Specials from './pages/Specials/Specials';
import Contact from './pages/Contact/Contact';
import AccountLogin from './pages/AccountLogin/AccountLogin';
import Register from './pages/Register/Register';
import Meetteam from './pages/Meetteam/Meetteam';
import AboutBEE from './pages/AboutBBBEEE/AboutBBBEEE';
import History from './pages/History/History';
import CheckoutCart from './pages/CheckoutCart/CheckoutCart';
import CheckoutCustomerDetails from './pages/CheckoutCustomerDetails/CheckoutCustomerDetails';
import CheckoutPayment from './pages/CheckoutPayment/CheckoutPayment';
import CheckoutSummary from './pages/CheckoutSummary/CheckoutSummary';
import { CheckoutPath } from './utils/checkout';
import CheckoutAccount from './pages/CheckoutAccount/CheckoutAccount';
import { useAuthContext } from './context/AuthContext';
import { AccountMenuList } from './pages';
import ProductCatalogue from './pages/ProductCatalogue/ProductCatalogue';
import Legal from './pages/Legal/Legal';
import Certificates from './pages/Certificates/Certificates';
import TrendingProducts from './pages/TrendingProducts/TrendingProducts';
import Wishlist from './pages/Wishlist/Wishlist';
import SpecialsBranch from './pages/SpecialsBranch/SpecialsBranch';
import customHistory from './utils/history';
import Response from './pages/Hr/Response';
import PaystackCallback from './pages/PaystackCallback/PaystackCallback';
import CheckoutDelivery from './pages/CheckoutDelivery/CheckoutDelivery';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import SearchProducts from './pages/SearchProducts/SearchProducts';
import FavoriteProducts from './pages/Favorites/FavoriteProducts';
import NewProducts from './pages/NewProducts/NewProducts';
import CustomPage from './pages/CustomPage/CustomPage';
import CustomPagePreview from './pages/CustomPagePreview/CustomPagePreview';
import VacancyInfo from './pages/Hr/vacancy-info';

interface ICustomRoute extends RouteProps {
  authenticationHandler?: () => boolean;
  isPrivate?: boolean;
}
const PermissionView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Permission} />;

// Custom routes to handle Public and Private Routes
const CustomRoute = ({
  isPrivate = false,
  authenticationHandler = (() => false),
  path,
  ...rest
}: ICustomRoute) => {
  const { linkedAccount, hasRoutePermission } = useAuthContext();
  const isAccountRoute = AccountMenuList.some((item) => item.path === path);
  const hasLinkedAccount = linkedAccount && linkedAccount.length > 0;
  // eslint-disable-next-line max-len
  const hasPermission = isAccountRoute && hasLinkedAccount ? hasRoutePermission(AccountMenuList.find((item) => item.path === path)?.platformRights ?? []) : true;

  //  Route Handling
  if ((isPrivate && authenticationHandler() && hasPermission) || !isPrivate) {
    return (
      <Route {...rest} render={() => rest.children} />
    );
  }
  if (isAccountRoute && !hasPermission) {
    message.error('Permission not granted');
    return <Route>{PermissionView}</Route>;
  }
  return <Redirect to={`/account/login?returnUrl=${path}`} />;
};

CustomRoute.defaultProps = {
  isPrivate: false,
  authenticationHandler: (() => false),
};

const CheckoutRouter = () => {
  const { isLoggedIn } = useAuthContext();

  const accountAuthHandler = () => isLoggedIn === true && isLoggedIn !== undefined;

  const layoutsRoutes = [
    {
      isPrivate: false,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.CART,
      exact: true,
      component: <CheckoutCart />,
    },
    {
      isPrivate: false,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.DELIVERY,
      exact: true,
      component: <CheckoutDelivery />,
    },
    {
      isPrivate: true,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.LOGIN,
      exact: true,
      component: <CheckoutAccount />,
    },
    {
      isPrivate: true,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.CUSTOMER,
      exact: true,
      component: <CheckoutCustomerDetails />,
    },
    {
      isPrivate: true,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.PAYMENT,
      exact: true,
      component: <CheckoutPayment />,
    },
    {
      isPrivate: true,
      authenticationHandler: accountAuthHandler,
      path: CheckoutPath.SUMMARY,
      exact: true,
      component: <CheckoutSummary />,
    },
    {
      isPrivate: false,
      authenticationHandler: accountAuthHandler,
      path: '/checkout/wishlist',
      exact: true,
      component: <Wishlist />,
    },
  ];

  return (
    <div>
      <Layouts.CheckoutLayout
        component={(
          <Switch>
            {layoutsRoutes.map((item) => (
              <CustomRoute
                key={item.path}
                path={item.path}
                exact={item.exact}
                isPrivate={item.isPrivate}
                authenticationHandler={item.authenticationHandler}
              >
                {item.component}
              </CustomRoute>
            ))}
            {/* TODO: nested routes not found page */}
          </Switch>
        )}
      />
    </div>
  );
};

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unListen = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unListen();
    };
  }, []);

  return null;
};

const CustomRouter = () => {
  const { isLoggedIn } = useAuthContext();

  const accountAuthHandler = () => isLoggedIn === true && isLoggedIn !== undefined;

  const HomeView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Home} />;
  const AboutView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={About} />;
  const ProductCatalogueView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={ProductCatalogue} />;
  const ProductsView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Products} />;
  const ProductsInfoView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={ProductInformation} />;
  const SpecialsView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Specials} />;
  const SpecialsBranchView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={SpecialsBranch} />;
  const ContactView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Contact} />;
  const AccountView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Account} />;
  const AccountLoginView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={AccountLogin} />;
  const LostView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Lost} />;
  const NotificationView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Notification} />;
  const RegisterView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Register} />;
  const Meetteamview = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Meetteam} />;
  const HRView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Response} />;
  const AboutBEEview = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={AboutBEE} />;
  const HistoryView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={History} />;
  const LegalView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Legal} />;
  const CertificatesView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={Certificates} />;
  const TrendingProductsView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={TrendingProducts} />;
  const PaystackCallbackView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={PaystackCallback} />;
  const PrivacyView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={PrivacyPolicy} />;
  const SearchProductView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={SearchProducts} />;
  const FavoriteProductsView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={FavoriteProducts} />;
  const NewProductsView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={NewProducts} />;
  const CustomPageView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={CustomPage} />;
  const CustomPagePreviewView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={CustomPagePreview} />;
  const VacancyInfoView = <Layouts.HeaderLayout headerContent={<Menu direction="row" />} component={VacancyInfo} />;

  return (
    <Router history={customHistory}>
      <ScrollToTop />
      <Switch>
        <CustomRoute path="/" exact>{HomeView}</CustomRoute>
        <CustomRoute path="/account/migrate" exact>{AccountView}</CustomRoute>
        <CustomRoute path="/account/login" exact>{AccountLoginView}</CustomRoute>
        <CustomRoute path="/about" exact>{AboutView}</CustomRoute>
        <CustomRoute path="/products/catalogue" exact>{ProductCatalogueView}</CustomRoute>
        <CustomRoute path="/products/trending" exact>{TrendingProductsView}</CustomRoute>
        <CustomRoute path="/products/favorites" exact>{FavoriteProductsView}</CustomRoute>
        <CustomRoute path="/products/new" exact>{NewProductsView}</CustomRoute>
        <CustomRoute path="/products/:categoryRef" exact>{ProductsView}</CustomRoute>
        <CustomRoute path="/products/:categoryRef/:subCategoryRef" exact>{ProductsView}</CustomRoute>
        <CustomRoute path="/products/:categoryRef/:subCategoryRef/:sku" exact>{ProductsInfoView}</CustomRoute>
        <CustomRoute path="/specials" exact>{SpecialsView}</CustomRoute>
        <CustomRoute path="/specials/national" exact>{SpecialsBranchView}</CustomRoute>
        <CustomRoute path="/specials/branch/:branchName/:branchId" exact>{SpecialsBranchView}</CustomRoute>
        <CustomRoute path="/contact" exact>{ContactView}</CustomRoute>
        <CustomRoute path="/account/register" exact>{RegisterView}</CustomRoute>
        <CustomRoute path="/about/team" exact>{Meetteamview}</CustomRoute>
        <CustomRoute path="/About/careers" exact>{HRView}</CustomRoute>
        <CustomRoute path="/about/history" exact>{HistoryView}</CustomRoute>
        <CustomRoute path="/about/bbbee" exact>{AboutBEEview}</CustomRoute>
        <CustomRoute path="/checkout"><CheckoutRouter /></CustomRoute>
        <CustomRoute path="/about/legal" exact>{LegalView}</CustomRoute>
        <CustomRoute path="/about/certificates" exact>{CertificatesView}</CustomRoute>
        <CustomRoute path="/about/certificates" exact>{CertificatesView}</CustomRoute>
        <CustomRoute path="/paystack/callback" exact>{PaystackCallbackView}</CustomRoute>
        <CustomRoute path="/notification/site-upgrade" exact>{NotificationView}</CustomRoute>
        <CustomRoute path="/About/Legal/Privacy-Policy" exact>{PrivacyView}</CustomRoute>
        <CustomRoute path="/products" exact>{SearchProductView}</CustomRoute>
        <CustomRoute path="/pages/:pageName" exact>{CustomPageView}</CustomRoute>
        <CustomRoute path="/pages/preview/:pageName" exact>{CustomPagePreviewView}</CustomRoute>
        <CustomRoute path="/about/careers/vacancy-info/:id" exact>{VacancyInfoView}</CustomRoute>

        {/* Possible Redirect urls */}
        <CustomRoute path="/g-fox-promotions">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/gfox-promotions-zam">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/g-fox-promotions-pe">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/g-fox-promotions-dbn">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/g-fox-promotions-ct">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/gfox-promotions-pol">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/g-fox-promotions-mid">
          <Redirect to="/products/new" />
        </CustomRoute>
        <CustomRoute path="/product-category/work-wear">
          <Redirect to="/products/work-wear" />
        </CustomRoute>
        <CustomRoute path="/product-category/cleaning-chemical-products">
          <Redirect to="/products/cleaning-chemical-products" />
        </CustomRoute>
        <CustomRoute path="/product-category/cleaning-equipment">
          <Redirect to="/products/cleaning-equipment" />
        </CustomRoute>
        <CustomRoute path="/product-category/footwear">
          <Redirect to="/products/footwear" />
        </CustomRoute>
        <CustomRoute path="/product-category/security-wear">
          <Redirect to="/products/security-wear" />
        </CustomRoute>
        <CustomRoute path="/product-category/paper-products">
          <Redirect to="/products/paper-products" />
        </CustomRoute>
        <CustomRoute path="/product-category/hand-protection">
          <Redirect to="/products/hand-protection" />
        </CustomRoute>
        <CustomRoute path="/cart">
          <Redirect to="checkout/cart" />
        </CustomRoute>
        <CustomRoute path="/gfox-product-catalogue">
          <Redirect to="/products/catalogue" />
        </CustomRoute>
        <CustomRoute path="/gfox-product-catalogue">
          <Redirect to="/products/catalogue" />
        </CustomRoute>
        <CustomRoute path="/legal">
          <Redirect to="/about/legal" />
        </CustomRoute>
        <CustomRoute path="/our-branches">
          <Redirect to="/contact" />
        </CustomRoute>
        <CustomRoute path="/our-branches">
          <Redirect to="/contact" />
        </CustomRoute>
        <CustomRoute path="/general-enquiries">
          <Redirect to="/contact" />
        </CustomRoute>
        <CustomRoute path="/products/cleaning-chemical-products">
          <Redirect to="/products/cleaning-products" />
        </CustomRoute>
        <CustomRoute path="/products/safety-equipment">
          <Redirect to="/products/safety" />
        </CustomRoute>
        <CustomRoute path="/products/safety-equipment">
          <Redirect to="/products/safety" />
        </CustomRoute>
        <CustomRoute path="/about/meet-the-team">
          <Redirect to="/about/team" />
        </CustomRoute>
        <CustomRoute path="/about/meet-the-team">
          <Redirect to="/about/team" />
        </CustomRoute>
        <CustomRoute path="/about/meet-the-team">
          <Redirect to="/about/team" />
        </CustomRoute>
        <CustomRoute path="/product-category/office-consumable">
          <Redirect to="/products/office-consumable" />
        </CustomRoute>
        <CustomRoute path="/my-account/edit-account">
          <Redirect to="/account/profile" />
        </CustomRoute>
        <CustomRoute path="/about/bbbee">
          <Redirect to="/about/BBBEE" />
        </CustomRoute>
        <CustomRoute path="/my-account/quotes">
          <Redirect to="/account/quotes" />
        </CustomRoute>

        {AccountMenuList.map((item) => (
          <CustomRoute
            key={item.path}
            authenticationHandler={accountAuthHandler}
            isPrivate
            path={item.path}
            exact
          >
            {/* TODO:  item.component  as JSX.Element */}
            <Layouts.AccountLayout component={<item.component />} />
          </CustomRoute>
        ))}
        <Route>{LostView}</Route>
      </Switch>
    </Router>
  );
};

export default CustomRouter;
