/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Select } from 'antd';
import { EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { ISurvey } from '@bridgelabsdesign/gfox-api-client';
import { useHistory } from 'react-router-dom';
import stores from '../../stores/stores';
import Spin from '../../components/Spin/Spin';
import './style.less';

const { Option } = Select;

const WORD_LIMIT = 30;

const Response = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { surveyStore, branchesStore } = stores;
  const [reportsRevArray, setReportsRevArray] = useState<ISurvey[]>([]);
  const [surveys, setSurveys] = useState<ISurvey[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>();
  const [expandedJobs, setExpandedJobs] = useState<{ [key: string]: boolean }>({});

  const getSurveys = async () => {
    await surveyStore.loadSurvey();
    setSurveys(surveyStore.surveys.value.data);
  };

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      await Promise.all([
        branchesStore.loadBranchNames(),
        surveys.length === 0 ? getSurveys() : Promise.resolve(),
      ]);
      setLoading(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    let filteredSurveys = surveyStore.surveys.value.data;

    filteredSurveys = filteredSurveys.filter(
      (job) => !job.closingDate || moment(job.closingDate).isAfter(moment()),
    );

    if (searchQuery) {
      filteredSurveys = filteredSurveys.filter((job) => job.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    if (selectedBranch) {
      filteredSurveys = filteredSurveys.filter((job) => job.branchId === selectedBranch);
    }

    setReportsRevArray(filteredSurveys);
  }, [surveyStore.surveys.value.data, searchQuery, selectedBranch]);

  const truncateText = (text: string, limit: number) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ');
    }
    return text;
  };

  const toggleJobExpansion = (jobId: string) => {
    setExpandedJobs((prev) => ({ ...prev, [jobId]: !prev[jobId] }));
  };

  const groupedSurveys = React.useMemo(() => reportsRevArray.reduce((acc, job) => {
    const branchName = job.branch?.branchName || 'Unknown Branch';
    if (!acc[branchName]) {
      acc[branchName] = [];
    }
    acc[branchName].push(job);
    return acc;
  }, {} as Record<string, ISurvey[]>), [reportsRevArray]);

  return (
    <Spin tip="Getting Job listings" spinning={loading}>
      <main id="reviews-root" className="productOfferCon">
        <div className="header-section">
          <h1 className="main-title">Job Listings</h1>
          <p className="subtitle">Find your next opportunity</p>
          <div className="active-positions">
            <div className="position-number">{reportsRevArray.length}</div>
            <div className="position-label">ACTIVE POSITIONS</div>
          </div>

          <div className="search-section">
            <div className="search-container">
              <Input
                size="large"
                placeholder="Search jobs..."
                prefix={<SearchOutlined style={{ color: '#CBD5E0' }} />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <Select
                size="large"
                placeholder="Select branch"
                value={selectedBranch}
                onChange={setSelectedBranch}
                className="province-select"
              >
                <Option value={undefined}>All Branches</Option>
                {branchesStore.branchList.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.branchName}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>

        {reportsRevArray.length > 0 ? (
          <div className="job-list">
            {Object.entries(groupedSurveys).map(([branchName, jobs]) => (
              jobs.map((job) => (
                <div key={job.id} className="job-card">
                  <div className="job-content">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/about/careers/vacancy-info/${job.id}`);
                      }}
                      className="job-title"
                    >
                      {job.name}
                    </a>
                    <p className="job-summary">
                      {expandedJobs[job.id]
                        ? job.jobSummary || 'No summary available for this job.'
                        : truncateText(job.jobSummary || 'No summary available for this job.', WORD_LIMIT)}
                      {(job.jobSummary || '').split(' ').length > WORD_LIMIT && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleJobExpansion(job.id);
                          }}
                          className="more-link"
                        >
                          {expandedJobs[job.id] ? 'less' : 'more'}
                        </a>
                      )}
                    </p>
                  </div>
                  <div className="job-meta">
                    <div className="job-location">
                      <EnvironmentOutlined />
                      <span>{job.branch?.branchName || 'Unknown Branch'}</span>
                    </div>
                    <div className="job-date">{moment(job.createdAt).fromNow()}</div>
                  </div>
                </div>
              ))
            ))}
          </div>
        ) : (
          <div className="empty-review">There are no scheduled reviews at the moment</div>
        )}
      </main>
    </Spin>
  );
};

export default Response;
