import React, { useEffect } from 'react';
import {
  Empty, Image, Skeleton, Table,
} from 'antd';
import { IoClose } from 'react-icons/io5';
import { IFavoriteProducts } from '@bridgelabsdesign/gfox-api-client';
import { observer } from 'mobx-react-lite';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Typography from '../Typography';
import Buttons from '../Buttons';
import styles from './checkout-item.module.css';
import { calcProductPrice, formatPrice } from '../../utils/pricing';
import stores from '../../stores/stores';
import Images from '../../assets';
import { useAuthContext } from '../../context/AuthContext';
import { imgURLPrefix } from '../../config/image';
import PricingInformation from '../Pricing/PricingInformation';
import { CheckoutPath } from '../../utils/checkout';

const { Link, Text } = Typography;

const ImageView = ({ isLoading, src }: {
  isLoading: boolean
  src: string,
}) => (
  <>
    {isLoading ? (
      <Skeleton
        active
        loading
        className={styles.carouselImgCon}
      />
    ) : (
      <Image
        src={`${imgURLPrefix}${src}`}
        alt="Product image"
        className={styles.cartItemImg}
        rootClassName={styles.cartItemImgCon}
        fallback={Images.FallbackImage}
      />
    )}
  </>
);

const CloseBtn = observer(({ favoriteId, edit }: { favoriteId:string, edit: boolean }) => {
  const { currentClient } = useAuthContext();
  const { favoriteStore } = stores;

  if (!edit) {
    return null;
  }
  return (
    <span className={styles.cartItemCloseIconCon}>
      <Buttons.PrimaryBtn
        type="text"
        icon={(
          <IoClose
            className={styles.cartItemCloseIcon}
          />
        )}
        onClick={() => favoriteStore.removeFavoritesItem(currentClient?.id!, [favoriteId])}
      />
    </span>
  );
});

interface IFavoriteItemsTableProps {
  edit?: boolean,
  items?: IFavoriteProducts[]
  loading?: boolean
}

const FavoriteItemsTable = observer(({
  edit = true,
  items,
  loading = false,
}: IFavoriteItemsTableProps) => {
  const { currentClient } = useAuthContext();
  const { favoriteStore, cartStore } = stores;
  const history = useHistory();

  useEffect(() => {
  // Define an async function inside the useEffect to handle async logic
    const fetchData = async () => {
      if (!favoriteStore.FavoritesItems.value.data.length) {
        await favoriteStore.loadFavoritesItems(currentClient?.id);
      }

      const favoriteItemSkus = favoriteStore.FavoritesItems.value.data
        .map((item) => item.inventoryMaster?.sku)
        .filter(Boolean);

      if (favoriteItemSkus.length > 0) {
        await favoriteStore.loadInventoryList({
          skuList: favoriteItemSkus.join(','),
          withPrice: true,
        });
      }
    };

    fetchData(); // Call the async function
  }, [currentClient, favoriteStore]);

  const favoriteItems = favoriteStore.FavoritesItems.value.data.slice().sort((a, b) => b?.inventoryMasterId?.localeCompare(a?.inventoryMasterId));

  const isLoading = favoriteStore.FavoritesItems.isLoading || favoriteItems.length === 0;

  const transferFavoritesToStore = async (favoritesList: IFavoriteProducts[]) => {
    await favoriteStore.transferFavoritesToCart(currentClient!, cartStore.cartItems.value.data, favoritesList);
    await cartStore.loadCartItems(currentClient!.id);
    history.push(CheckoutPath.CART);
  };

  const tableColumns = [
    {
      title: '',
      dataIndex: 'imageSrc',
      key: 'imageSrc',
      width: '12%',
      render: (text: string, record: IFavoriteProducts) => (
        <ImageView
          isLoading={loading ?? isLoading}
          src={record?.inventoryMaster?.productImages?.length > 0
            ? record?.inventoryMaster?.productImages[0]
            : Images.FallbackImage}
        />
      ),
    },
    {
      title: 'Product',
      dataIndex: 'sku',
      key: 'sku',
      width: '60%',
      render: (text: string, record: IFavoriteProducts) => (
        record && (
          <div className={styles.cartTableProductInfo}>
            <Text className={styles.cartItemName}>
              {record.inventoryMaster?.description}
            </Text>
            <div>
              {record.inventoryMaster?.categorySpecificInfo?.map((value) => (
                <span key={value.name} style={{ paddingRight: '1em' }}>
                  <Text className={styles.cartItemDetailsFieldName}>
                    {value.name}
                    { ': '}
                  </Text>
                  <Text className={styles.cartItemDetailsFieldValue}>
                    {value.value}
                  </Text>
                </span>
              ))}
            </div>
            <Text className={styles.cartItemDetailsSku}>
              {record.inventoryMaster?.sku}
            </Text>
          </div>
        )
      ),
    },
    {
      title: '',
      key: 'addToCart',
      render: (text: string, record: IFavoriteProducts) => {
        const inv = favoriteStore.invList.value.data
          .find((x) => x.id === record.inventoryMasterId);
        if (!inv) {
          return null;
        }
        return (
          <>
            <PricingInformation
              className={styles.pricingInfo}
              pricing={{
                masterStock: inv.masterStock,
                accountPricing: inv.accountPricing,
              }}
            />
            <br />
          </>
        );
      },
    },
    {
      title: '',
      width: '8%',
      key: 'addToCart',
      render: (a: string, record: IFavoriteProducts) => (
        <div style={{ display: 'flex' }}>
          <span className={styles.cartItemCloseIconCon}>
            <Buttons.PrimaryBtn
              type="text"
              onClick={() => transferFavoritesToStore([record])}
              icon={(
                <Icon
                  className={styles.cartItemCloseIcon}
                  component={Images.ShoppingCartIcon}
                  color="#d8232a"
                />
              )}
            />
          </span>
        </div>
      ),
    },
    {
      title: '',
      width: '8%',
      key: 'close',
      render: (a: string, record: IFavoriteProducts) => (
        <CloseBtn favoriteId={record.id} edit={edit} />
      ),
    },
  ];

  const getInvItemPrice = (invSku: string, qty: number) => {
    const invItem = favoriteStore.invList.value.data.find((x) => x.sku === invSku);
    const price = calcProductPrice(invItem).excludingTax * qty;
    return price > 0 ? formatPrice(price) : '';
  };

  return (
    <>
      <div className={['checkout-items-table', styles.cartMobView].join(' ')}>
        {React.Children.toArray(
          (items ?? favoriteItems).map((item) => (
            <div className={styles.cartItemCon}>
              <CloseBtn favoriteId={item.id} edit={edit} />
              <Text className={styles.cartItemName}>
                {item?.inventoryMaster?.description}
              </Text>
              <div className={styles.cartItemDetailsCon}>
                <ImageView
                  isLoading={isLoading || loading}
                  src={item?.inventoryMaster?.productImages?.length > 0
                    ? item?.inventoryMaster?.productImages[0]
                    : Images.FallbackImage}
                />
                <div className={styles.cartItemDetails}>
                  <Text className={styles.cartItemDetailsSku}>
                    {item.inventoryMaster?.sku}
                  </Text>
                  {item.inventoryMaster?.categorySpecificInfo?.map((value) => (
                    <div key={value.name}>
                      <Text className={styles.cartItemDetailsFieldName}>
                        {value.name}
                        {': '}
                      </Text>
                      <Text className={styles.cartItemDetailsFieldValue}>
                        {value.value}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.cartItemPriceCon}>
                <Buttons.CartCountBtn
                  quantity={item.quantity}
                  size="small"
                  showEditButton={edit}
                  onAddClick={() => favoriteStore.updateToFavoritesItems(currentClient?.id!, item?.id, 1)}
                  onMinusClick={() => favoriteStore.updateToFavoritesItems(currentClient?.id!, item?.id, -1)}
                  handleQuantityChange={(e) => {
                    const value = e.target.value === '' ? 0 : Number(e.target.value);
                    favoriteStore.updateToFavoritesItems(currentClient?.id!, item?.id, Number(value), true);
                  }}

                />
                <Text className={styles.cartItemPrice}>
                  {getInvItemPrice(item.inventoryMaster?.sku ?? '', item.quantity)}
                </Text>
              </div>
            </div>
          )),
        )}
      </div>
      <div className={styles.cartTableView}>
        {/* TODO: format table header & add spacing between rows */}
        <Table
          dataSource={items ?? favoriteItems}
          columns={tableColumns}
          pagination={false}
          size="small"
          bordered={false}
          rowClassName={styles.cartTableRow}
          locale={{
            emptyText: (
              <div className={styles.emptyListCon}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
                <Link
                  className={styles.emptyListShop}
                  href="/specials"
                >
                  Continue Shopping
                </Link>
              </div>
            ),
          }}
        />
      </div>

    </>
  );
});

export default FavoriteItemsTable;
