import React, { useEffect, useMemo, useState } from 'react';
import {
  Form as AntdForm, Modal, Image, Empty, message,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BsCircleFill, BsTrash, BsCheckSquare, BsSquare,
} from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { useForm } from 'antd/lib/form/Form';
import { runInAction } from 'mobx';
import {
  IClientAddress, IInventoryMaster,
} from '@bridgelabsdesign/gfox-api-client';
import { validateMessages } from '../../utils/form';
import Typography from '../Typography';
import Images from '../../assets';
import stores from '../../stores/stores';
// import { itemOutOfStock } from '../../utils/order/stock';
import { formatPrice, calcProductPrice } from '../../utils/pricing';
import styles from './checkout-summary.module.css';
import { useAuthContext } from '../../context/AuthContext';
import Modals from '../Modals';
import Form from '../Form';
import Buttons from '../Buttons';
import { getStockMessage } from '../../utils/order/stockMessage';
import { CheckoutPath } from '../../utils/checkout';

const { Text } = Typography;
const SummaryInfo = observer(({
  item,
}: {
  item: IInventoryMaster
}) => {
  const {
    cartStore,
  } = stores;
  const pricingInfo = useMemo(() => calcProductPrice(item), [item]);
  const { currentClient } = useAuthContext();
  // eslint-disable-next-line max-len
  const masterStock = cartStore.invList.value.data.find((x) => x.id === item.id)?.masterStock;
  // const [realTimeStock, setRealTimeStock] = useState<IStockQuantityResponse | null>(null);

  // useEffect(() => {
  //   const stockQuantity = cartStore.realTimeStockQuantity.find((stock) => stock.sku === masterStock?.stockCode);
  //   setRealTimeStock(stockQuantity || null);
  // }, [cartStore.realTimeStockQuantity]);

  const stockMessage = getStockMessage({ lineItemCount: item.orderQty },
    { isAppPrice: masterStock?.isAppPrice ?? false, qtyAvailable: masterStock?.qtyAvailable ?? 0 }, currentClient);

  return (
    <>
      <Text className={styles.sideConTextName}>
        {item?.description}
      </Text>
      <div className={styles.sideConTextPriceCon}>
        <Text className={styles.sideConTextPrice}>
          {formatPrice(pricingInfo.excludingTax)}
        </Text>
        {pricingInfo.crossOutPrice && (
          <Text className={styles.sideConTextPriceOut}>
            {formatPrice(pricingInfo.crossOutPrice)}
          </Text>
        )}
      </div>
      <Text className={styles.sideConTextQty}>
        {`x${item.orderQty ?? 1}`}
      </Text>

      <Text className={styles.backOrderText}>
        {stockMessage.message}
      </Text>
    </>
  );
});

type VoucherSelectFormType = {
  voucherClaimId: string;
}

const CheckoutSummaryCard = observer(() => {
  const [invItems, setInvItems] = useState<IInventoryMaster[]>([]);
  const [isClaimVoucherModalVisible, setIsClaimVoucherModalVisible] = useState(false);
  const [showVoucherSelection, setShowVoucherSelection] = useState(false);
  const [form] = useForm<VoucherSelectFormType>();
  const { currentClient } = useAuthContext();
  const isAccHolder = !!currentClient?.accountNumber;
  const {
    addressStore, cartStore, checkoutStore, deliveryStore, vouchersStore,
  } = stores;

  const history = useHistory();
  const location = useLocation();

  const currentStep = checkoutStore.checkoutStep;
  const cartItems = cartStore.cartItems.value.data;
  const type = currentStep?.path !== CheckoutPath.CART ? 'default' : 'text';
  const itemsVatPricing = cartStore.cartItemsInvPricing.vatTotal;
  const itemsInvPricing = cartStore.cartItemsInvPricing.totalWithVat;
  const invList = cartStore.invList.value.data;
  const itemContentLoading = cartStore.cartItems.isLoading;
  const itemContent = cartStore.cartItems.value.data;

  const handleSelectAddresses = (clientAddress: IClientAddress[]) => {
    if (!addressStore.selectedAddress) {
      addressStore.setSelectedAddressFromClient(clientAddress);
    }
    if (!addressStore.selectedAddress) {
      message.info('Please add your shipping address.');
      history.push(`/account/profile/address?returnUrl=${location.pathname}`);
    }
  };

  const handleClaimDiscount = async () => {
    if (!currentClient) {
      return;
    }

    await vouchersStore.loadVoucherClaims(currentClient.id!);
    if (vouchersStore.voucherClaims.length === 0) {
      setIsClaimVoucherModalVisible(true);
    } else {
      setShowVoucherSelection(true);
    }
  };

  const handleOnSelectVoucherForm = async (values: VoucherSelectFormType) => {
    try {
      const updatedInvItems = await vouchersStore.setApplyVoucherPricing(values.voucherClaimId, invItems);
      runInAction(() => { cartStore.invList.value.data = updatedInvItems; });
      const claim = vouchersStore.voucherClaims.find((x) => x.id === values.voucherClaimId);
      if (!claim?.voucher?.refNo) {
        cartStore.setVoucherClaim(undefined);
      } else {
        cartStore.setVoucherClaim({ claimId: values.voucherClaimId, refNo: claim?.voucher?.refNo });
      }
      message.success('Voucher applied');
    } catch (err) {
      message.error('Could not apply voucher discount');
      // eslint-disable-next-line no-console
      console.error('voucherStore apply pricing err:', err);
    }

    setIsClaimVoucherModalVisible(false);
    setShowVoucherSelection(false);
  };

  useEffect(() => {
    // eslint-disable-next-line max-len
    checkoutStore.setTotalPrice(itemsInvPricing + (deliveryStore.selectedDelivery?.deliveryOrders[0]?.price ?? 0));
  }, [itemsInvPricing, deliveryStore.selectedDelivery]);

  useEffect(() => {
    checkoutStore.setTotalPriceVat(itemsVatPricing);
  }, [itemsVatPricing]);

  useEffect(() => {
    const cartQty: { [sku: string]: number } = cartItems.reduce((p, c) => ({ ...p, [c.inventoryMaster?.sku]: c.quantity }), {});
    const inv = invList.slice()
      .sort((a, b) => b?.id?.localeCompare(a?.id))
      .map((x) => ({ ...x, orderQty: cartQty[x.sku] }));
    setInvItems(inv);
  }, [cartItems, invList]);

  useEffect(() => {
    if (currentClient?.id) {
      handleSelectAddresses(currentClient?.clientAddresses);
    }
  }, [currentClient]);/// cartStore.invList.value.data, cartStore.cartItems]);

  useEffect(() => {
    (async () => {
      if (currentClient?.id && addressStore.selectedAddress?.id) {
        await deliveryStore.fetchDeliveryPricing(invItems, currentClient?.id, addressStore.selectedAddress?.id, currentClient?.accountNumber);
      }
    })();
  }, [currentClient?.id, invItems, addressStore.selectedAddress]);

  useEffect(() => {
  }, [deliveryStore.selectedDelivery]);
  const skuCount: { [sku: string]: number } = {};
  cartItems.forEach((item) => {
    const sku = item.inventoryMaster?.skuId;
    if (sku) {
      skuCount[sku] = (skuCount[sku] || 0) + 1;
    }
  });

  const hasDuplicates = Object.values(skuCount).some((count) => count > 1);

  const isCartPage = location.pathname === '/checkout/cart';

  if (cartItems.length === 0 || invItems.length !== cartItems.length) {
    if (hasDuplicates) {
      if (isCartPage) {
        return (
          <div className={styles.emptyListCon}>
            <span style={{ color: 'red' }}>Remove duplicate items inside cart</span>
          </div>
        );
      }
      return (
        <div className={styles.emptyListCon}>
          <span style={{ color: 'red' }}>
            Return to cart to remove duplicate items
          </span>
          <br />
          <Buttons.PrimaryBtn
            type="ghost"
            onClick={() => history.push('/checkout/cart')}
          >
            Go to Cart
          </Buttons.PrimaryBtn>
        </div>
      );
    }

    return (
      <div className={styles.emptyListCon}>
        <Empty
          className={styles.emptyList}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      </div>
    );
  }

  return (
    <div
      className={[
        styles.checkoutSidePanelContent,
        type === 'text' && styles.checkoutSidePanelContentText,
      ].join(' ')}
    >
      <Text className={styles.sideTotalItems}>
        Total Items:
        {' '}
        {cartItems.reduce((p, c) => p + c.quantity, 0)}
      </Text>

      {invItems.map((item, idx) => (
        <div
          key={item.sku}
          className={styles.sideCon}
        >
          <Text className={styles.sideConIndex}>
            {`${idx + 1}.`}
          </Text>
          <Image
            src={item?.productImages[0]}
            alt="Product image"
            className={styles.cartItemImg}
            rootClassName={styles.cartItemImgCon}
            fallback={Images.FallbackImage}
          />
          <div className={styles.sideConText}>
            <SummaryInfo item={item} />
          </div>
        </div>
      ))}

      <div
        className={[
          styles.deliveryHeader, styles.claimDiscountHeader,
        ].join(' ')}
      >
        <Text
          onClick={() => handleClaimDiscount()}
        >
          + Apply discount
        </Text>
      </div>
      {cartStore.voucherClaim && (
        <div
          className={[
            styles.deliveryOptsCon, styles.deliveryOptsConActive,
          ].join(' ')}
        >
          <span
            className={styles.deliveryOpts}
            onClick={() => setShowVoucherSelection(true)}
            aria-hidden
          >
            <BsCircleFill
              className={styles.deliveryOptsIcon}
            />
            <Text>
              {cartStore.voucherClaim?.refNo ?? ''}
            </Text>
          </span>
          <BsTrash
            onClick={async () => {
              cartStore.setVoucherClaim(undefined);
              await cartStore.loadCartItems(currentClient?.id!);
            }}
          />
        </div>
      )}

      <div className={styles.deliveryHeader}>
        <Text>
          + Delivery charge
        </Text>
      </div>
      {deliveryStore.deliveryOptions.map((item) => (
        <div
          key={item.refNo}
          className={[
            styles.deliveryOptsCon,
            item.refNo === deliveryStore.selectedDelivery?.refNo && styles.deliveryOptsConActive,
          ].join(' ')}
          onClick={() => deliveryStore.setSelectedDelivery(item)}
          aria-hidden
        >
          <span className={styles.deliveryOpts}>
            {item.refNo === deliveryStore.selectedDelivery?.refNo ? (
              <BsCheckSquare className={styles.deliveryOptsIcon} />
            ) : (
              <BsSquare className={styles.deliveryOptsIcon} />
            )}
            <Text>
              {item.description}
            </Text>
          </span>
          <Text>
            {formatPrice(item?.deliveryOrders[0]?.price ?? 0)}
          </Text>
        </div>
      ))}

      {(!addressStore.selectedAddress?.hubCode && !isAccHolder) && (
        <Buttons.LinkBtn
          type="link"
          link={{
            href: `/account/profile?returnUrl=${location.pathname}`,
          }}
          style={{
            display: 'block',
            wordWrap: 'break-word',
            whiteSpace: 'normal',
          }}
        >
          Please click
          to add a valid address
        </Buttons.LinkBtn>
      )}

      <div className={styles.vatPriceCon}>
        <Text className={styles.sideConTextName}>
          Vat
        </Text>
        <Text className={styles.sideConTextPrice}>
          {formatPrice(checkoutStore.totalPriceVat)}
        </Text>
      </div>
      <div className={styles.totalPriceCon}>
        <div>
          <Text className={styles.totalPriceHeader}>
            Total amount
          </Text>
          <Text className={styles.totalPriceSubHeader}>
            Inc VAT
          </Text>
        </div>
        <Text className={styles.totalPrice}>
          {formatPrice(checkoutStore.totalPrice)}
        </Text>
      </div>

      {!itemContentLoading && itemContent.length === 0 && (
        <div className={styles.emptyListCon}>
          <Empty
            className={styles.emptyList}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        </div>
      )}

      <Modal
        title="Apply discount"
        visible={showVoucherSelection}
        width={570}
        onOk={() => form.submit()}
        onCancel={() => setShowVoucherSelection(false)}
        okText="Continue"
        cancelText="Cancel"
        okButtonProps={{ style: { height: '3em' } }}
        cancelButtonProps={{ style: { height: '3em' } }}
        closeIcon={(<IoClose />)}
      >
        <AntdForm<VoucherSelectFormType>
          form={form}
          name="account-vouchers"
          validateMessages={validateMessages}
          className="custom-form"
          onFinish={handleOnSelectVoucherForm}
          onFinishFailed={() => message.error('Could not claim voucher')}
          layout="vertical"
          requiredMark={false}
          style={{ width: '100%' }}
        >
          <Text style={{ color: 'red', fontSize: '1.2rem' }}>
            Please note: voucher cannot be used on quotes. Only orders.
          </Text>
          <br />
          <AntdForm.Item
            name="voucherClaimId"
            rules={[{ required: true }]}
          >
            <Form.Select
              placeholder="Select your voucher"
              options={vouchersStore.voucherClaims.map((x) => ({
                value: x.id,
                label: `${x.voucher?.refNo ?? ''} (${x.voucher?.title ?? ''})`,
              }))}
            />
          </AntdForm.Item>

          <Buttons.PrimaryBtn
            type="ghost"
            onClick={async () => {
              setIsClaimVoucherModalVisible(true);
              await vouchersStore.loadVoucherClaims(currentClient?.id!);
            }}
          >
            Enter new code
          </Buttons.PrimaryBtn>
        </AntdForm>
      </Modal>

      <Modals.ClaimVoucher
        isVisible={isClaimVoucherModalVisible}
        setIsVisible={(v) => setIsClaimVoucherModalVisible(v)}
        onSubmit={(claim) => {
          handleOnSelectVoucherForm({ voucherClaimId: claim.id });
        }}
      />
    </div>
  );
});

export default CheckoutSummaryCard;
