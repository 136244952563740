/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Button, message } from 'antd';
import {
  EnvironmentOutlined, ClockCircleOutlined, ShareAltOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { ISurvey } from '@bridgelabsdesign/gfox-api-client';
import ReviewDrawer from '../../components/Drawers/ReviewDrawer';
import './vacancy-info.less';
// eslint-disable-next-line import/order
import { useParams } from 'react-router-dom';
import stores from '../../stores/stores';

const JsonToHtml = (jsonString: string) => {
  const content = JSON.parse(jsonString);
  let html = '';

  const processNode = (node: any) => {
    switch (node.type) {
      case 'list':
        // Handle list (ul/ol)
        // eslint-disable-next-line no-case-declarations
        const listTag = node.listType === 'bullet' ? 'ul' : 'ol';
        html += `<${listTag}>`;
        node.children.forEach((child: any) => processNode(child));
        html += `</${listTag}>`;
        break;

      case 'listitem':
        // Handle list items
        html += '<li>';
        node.children.forEach((child: any) => processNode(child));
        html += '</li>';
        break;

      case 'paragraph':
        // Handle paragraphs
        html += '<p>';
        node.children.forEach((child: any) => processNode(child));
        html += '</p>';
        break;

      case 'text':
        // Handle text with styles
        // eslint-disable-next-line no-case-declarations
        const style = node.style ? ` style="${node.style}"` : '';
        html += node.format === 9
          ? `<span${style}>${node.text}</span>`
          : node.text;
        break;

      default:
        // Handle any nested children
        if (node.children) {
          node.children.forEach((child: any) => processNode(child));
        }
    }
  };

  // Process the root node
  if (content.root && content.root.children) {
    content.root.children.forEach((node: any) => processNode(node));
  }

  return html;
};

const VacancyInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { surveyStore } = stores;
  const [job, setJob] = useState<ISurvey | undefined>();
  const [revDrawerVisible, setRevDrawerVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState<ISurvey | null>(null);

  useEffect(() => {
    const loadJob = async () => {
      if (id) {
        const response = await surveyStore.loadSurveyById(id);
        setJob(response);
      }
    };

    loadJob();
  }, [id]);

  const handleApply = (job: ISurvey) => {
    setSelectedReview(job);
    setRevDrawerVisible(true);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Job Vacancy',
        text: 'Check out this job vacancy!',
        url: window.location.href,
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      console.log('Share not supported on this browser, do it the old way.');
    }
  };

  const parseContent = (content: string | undefined) => {
    if (!content) {
      return '<p>Content not available</p>';
    }

    try {
      return JsonToHtml(content);
    } catch (error) {
      console.error('Error parsing content:', error);
      return '<p>Content not available</p>';
    }
  };

  const htmlContent = parseContent(job?.openingMessage);

  return (
    <div className="vacancy-info-container">
      <div className="vacancy-info-header">
        <h1 className="vacancy-title">{job?.name || 'Missing content'}</h1>
        <div className="vacancy-meta-info">
          <div className="meta-items">
            <span className="meta-item">
              <EnvironmentOutlined />
              {' '}
              {job?.branch.branchName || 'No location'}
            </span>
            <span className="meta-item">
              <ClockCircleOutlined />
              {' '}
              Posted:
              {' '}
              { job?.createdAt ? moment(job.createdAt).fromNow() : '1 week ago'}
            </span>
            <span className="meta-item">
              <CalendarOutlined />
              Closing Date:
              {' '}
              {job?.closingDate ? moment(job.closingDate).format('MMM Do, YYYY') : 'N/A'}
            </span>
          </div>
        </div>
        <div className="action-buttons">
          <Button type="primary" danger className="apply-button" onClick={() => handleApply(job!)}>
            Apply Now
          </Button>
          <div className="secondary-actions">
            <Button icon={<ShareAltOutlined />} onClick={handleShare} />
          </div>
        </div>
      </div>

      <div className="vacancy-info-content">
        <div
          className="section-content"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>

      <ReviewDrawer
        visible={revDrawerVisible}
        setVisible={setRevDrawerVisible}
        reviewsList={[]}
        selectedReview={selectedReview}
      />
    </div>
  );
};

export default VacancyInfo;
