import React, {
  ReactNode, useCallback, useEffect, useState,
} from 'react';
import Icon, { StarOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Badge, message } from 'antd';
import { AccountPermission } from '@bridgelabsdesign/gfox-api-client';
import { MenuContainer, MenuItemContainer } from './styles';
import styles from './styles.module.css';
import Images from '../../assets';
import MenuList from '../../pages';
import Typography from '../Typography';
import Form from '../Form';
import Avatar from '../Avatar/Avatar';
import MobileMenu from '../MobileMenu/MobileMenu';
import ProductListMenu from './ProductListMenu';
import { useAuthContext } from '../../context/AuthContext';
import SearchResults from './SearchResults';
import stores from '../../stores/stores';
import { isEmptyString } from '../../utils/strings';
import * as wishlistMigration from '../../utils/WishlistMigrate/WishlistMigration';

interface MenuProps {
  direction: 'row' | 'column';
  logo?: ReactNode;
}

const { Text, Link } = Typography;

const Menu = observer(({ direction, logo }: MenuProps) => {
  const [expandMenu, setExpandMenu] = useState(false);

  const history = useHistory();

  const {
    logoutUser, isLoggedIn, currentClient, authKey, generateAuthKey,
  } = useAuthContext();

  const {
    cartStore, categoryStore, clientStore, linkedAccountsStore, searchStore, favoriteStore,
    chatStore,
  } = stores;

  const activeRoute = useCallback((routeName: any) => window.location.href.indexOf(routeName) > -1, [MenuList]);

  const isProductRoute = useCallback((path) => path === '/products', []);
  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchStore.setSearchQuery(e.target.value);
    const valueNullOrEmpty = isEmptyString(e.target.value);
    searchStore.toggleShowSearchPanel(!valueNullOrEmpty);
  };

  const handleMenuItemClick = (e: React.MouseEvent<Element, MouseEvent>, path?: string) => {
    if (isProductRoute(path)) {
      e.preventDefault();
    }
  };

  const handleLoginLogout = async (e: React.MouseEvent<Element, MouseEvent>) => {
    if (!isLoggedIn) {
      return;
    }
    e.preventDefault();
    await logoutUser!();
    history.replace('/account/login');
    setExpandMenu(false);
  };

  useEffect(() => {
    categoryStore.loadCategory();
  }, []);

  React.useEffect(() => {
    if (currentClient?.id && !chatStore.isConnectionSetup) {
      const webSocketAuthKey = generateAuthKey!(currentClient.userAuth.emailAddress, currentClient.userAuth.password);
      chatStore.startMessageConnection(currentClient?.id, webSocketAuthKey);
    }
  }, [currentClient?.id, authKey, chatStore, chatStore.isConnectionSetup]);

  const handleReLinkAccount = async () => {
    const emailAddress = currentClient?.userAuth?.emailAddress;
    if (!currentClient || !emailAddress) {
      message.error('Could not link account');
      return;
    }

    // TODO: relink account to not set permission to admin
    await linkedAccountsStore.requestAccountLink(currentClient?.accountNumber, emailAddress, [AccountPermission.Admin]);
  };

  useEffect(() => {
    wishlistMigration.autoWishlistMigration(currentClient ?? undefined);
    cartStore.loadCartItems(currentClient?.id);
    favoriteStore.loadFavoritesItems(currentClient?.id);
    if (currentClient?.id) {
      clientStore.accountValid(currentClient?.id, {
        accountNumber: currentClient?.accountNumber,
        onReRequestValidation: () => handleReLinkAccount(),
        sendOnEmail: false,
      });
    }
  }, [currentClient?.id]);
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const unListen = history.listen((location, action) => {
      const queryParams = new URLSearchParams(location.search);
      const searchQueryParam = queryParams.get('search') || '';
      if (searchQueryParam) {
        searchStore.setSearchQuery(searchQueryParam);
        searchStore.toggleShowSearchPanel(true);
        searchStore.loadSearchResults(searchStore.searchQuery);
      }
    });

    return () => unListen();
  }, []);

  return (
    <>
      <MenuContainer
        className={styles.menuContainer}
        flexDirection={direction}
      >
        <Link
          href="/"
          className={styles.logoContainer}
          onClick={() => setExpandMenu(false)}
        >
          {logo ?? <img className={styles.gfoxLogo} src={Images.GFoxLogo} alt="G. Fox logo" />}
        </Link>

        <div className={styles.searchContainer}>
          <Icon
            className={[styles.icon, styles.searchIcon].join(' ')}
            component={Images.SearchIcon}
            onClick={() => searchStore.toggleShowSearchPanel()}
          />
          <Form.SearchInput
            placeholder="Search for items and brands"
            className={styles.searchInput}
            onChange={handleSearchInput}
            value={searchStore.searchQuery}
          />
        </div>

        {MenuList.map((menuItem) => (
          <MenuItemContainer
            key={menuItem.title}
            className={[
              styles.menuItemLink,
              isProductRoute(menuItem.path) && styles.menuItemLinkHoverBg,
            ].join(' ')}
          >
            <Link
              href={menuItem.path}
              onClick={(e) => handleMenuItemClick(e, menuItem.path)}
            >
              {menuItem.icon}
              <Text
                className={[
                  styles.menuItemText,
                  activeRoute(menuItem.path) ? styles.menuItemActiveText : '',
                ].join(' ')}
              >
                {menuItem.title}
              </Text>
            </Link>

            {isProductRoute(menuItem.path) && (
            <div className={styles.menuItemProductMenu}>
              <ProductListMenu
                  // TODO: hide ProductListMenu onNavItemClick
                onNavItemClick={() => {}}
              />
            </div>
            )}
          </MenuItemContainer>
        )) }

        {searchStore.showSearchPanel && (
        <div className="navbar-padding">
          <SearchResults />
        </div>
        )}

        <div className={styles.menuVerticalSeparator} />

        <div className={styles.accountContainer}>
          {/* TODO: setup account route */}
          <Link
            href="/account/login"
            className={styles.lockContainer}
            onClick={(e) => handleLoginLogout(e)}
          >
            <Icon
              className={styles.icon}
              component={isLoggedIn ? Images.LockUnLockIcon : Images.LockIcon}
            />
            <Text className={styles.lockLoginText}>
              {isLoggedIn ? 'Logout' : 'Login/Sign up'}
            </Text>
          </Link>

          {/* TODO: setup cart route */}
          <Link
            href="/checkout/cart"
            className={styles.cartContainer}
            onClick={() => setExpandMenu(false)}
          >
            <Badge
              count={cartStore.cartCount}
              className={styles.cartBadge}
              color="#d8232a"
            >
              <Icon
                className={styles.icon}
                component={Images.ShoppingCartIcon}
              />
            </Badge>
          </Link>
          <Link
            href="/products/favorites"
            className={styles.cartContainer}
            onClick={() => setExpandMenu(false)}
          >
            <Badge
              count={favoriteStore.FavoritesCount}
              className={styles.cartBadge}
              color="#d8232a"
            >
              <StarOutlined
                className={styles.icon}
              />
            </Badge>
          </Link>

          <span
            className={styles.avatarIconContainer}
          >
            <Avatar
              onClick={() => history.push('/account/dashboard')}
              size="large"
            />
          </span>
        </div>

        <div className={styles.menuItemContainer}>
          <Icon
            className={[styles.icon, styles.menuIcon].join(' ')}
            component={Images.MenuIcon}
            onClick={() => setExpandMenu(!expandMenu)}
          />
        </div>
      </MenuContainer>

      {expandMenu && (
        <div
          className={[styles.extendMenuContainer, styles.extendMenuContainerMobile].join(' ')}
        >
          <MobileMenu
            onNavigate={() => setExpandMenu(false)}
          />
        </div>
      )}

    </>
  );
});

export default Menu;
